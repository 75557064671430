import React, { useState, useEffect } from 'react'
import routes from './router/config.js'
import RouteView from "./router/index";
import intl from 'react-intl-universal';
const locales = {
  "en": require('./locales/en-US.json'),
  "zh": require('./locales/zh-CN.json'),
};

const App = (props) => {
  const [initDone, setInitDone] = useState(false)
  useEffect(() => {
    initValue()
  }, [])
  const initValue = () => {
    const lang = (navigator.language || navigator.browserLanguage).toLowerCase();
    if (lang.indexOf('zh') >= 0) {
      localStorage.setItem("defaultLng", "zh")
    } else {
      localStorage.setItem("defaultLng", "en")
    }
    loadLocales();
  }
  const loadLocales = () => {
    intl.init({
      currentLocale: localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh',
      locales,
    }).then(() => {
      setInitDone(true)
    });
  }

  return (
    initDone &&
    <RouteView routes={routes}>
    </RouteView>
  )
}
export default App
